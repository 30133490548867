import React, { useState } from 'react';
import { graphql } from 'gatsby';
import {
  ContactMap,
  SocialShare,
  PageLayout,
  EnquireFormModal,
  TrainerSection,
} from 'components';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const LocationDetail = ({ data }: any) => {
  const {
    strapiLocations: {
      title,
      content,
      heading,
      seo,
      image,
      latitude,
      longitude,
    },
    strapiGlobal: { banner },
  } = data;

  const lat = parseFloat(latitude);
  const lng = parseFloat(longitude);

  const contactModel = data.strapiContactUsPage.locationsSection;
  const [isEnquireModalOpen, setisEnquireModalOpen] = useState(false);
  const handleEnquireModalOpen = () => {
    setisEnquireModalOpen(true);
  };
  const locationImage: any =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  const trainer = data?.TrainerContent?.locations?.filter((item: any) => {
    return item.title === title;
  });
  return (
    <PageLayout
      title={title}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      type="Location"
      delaySec={0.3}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      dealTitle="Locations Page"
    >
      <div className="container">
        <div className="locationdetails">
          {heading && <h1 className="location-detail-heading">{heading}</h1>}
          <div className="location-detail">
            <div className="location-detail-services">
              <div className="location-detail-list-image">
                <GatsbyImage
                  image={locationImage}
                  alt={(image?.alternativeText && title) || ''}
                />
              </div>
              <div className="location-detail-services-map">
                {lat && lng && (
                  <ContactMap
                    defaultZoom={10}
                    defaultCenter={{
                      lat,
                      lng,
                    }}
                    locations={[
                      {
                        locationName: title,
                        address: title,
                        position: {
                          lat,
                          lng,
                        },
                      },
                    ]}
                  />
                )}
              </div>
              <div className="location-detail-services-contact">
                <SocialShare />
                <div className="enquireButton-container">
                  <button
                    className="enquireButton"
                    type="button"
                    onClick={handleEnquireModalOpen}
                  >
                    <span>{contactModel.contactButtonText}</span>
                  </button>
                </div>
              </div>
            </div>
            <EnquireFormModal
              isEnquireModalOpen={isEnquireModalOpen}
              setisEnquireModalOpen={setisEnquireModalOpen}
              contactFormModalTitle={contactModel.contactFormModalTitle}
              optionalMessage
              dealTitle={title}
            />

            <div className="location-detail-list">
              <h3 className="location-detail-list-subheading">{title}</h3>
              {content && (
                <div className="location-detail-list-content">
                  <ReactMarkdown className="md-html">{content}</ReactMarkdown>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {data.Trainers.edges.length > 0 && trainer[0]?.trainers && (
        <TrainerSection
          personalTrainer={data.Trainers.edges}
          sectionHeading={trainer[0]?.trainers}
        />
      )}
    </PageLayout>
  );
};

export default LocationDetail;

export const query = graphql`
  query LocationsQuery($title: String) {
    strapiLocations(title: { eq: $title }) {
      title
      slug
      latitude
      longitude
      address
      content
      heading
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
    }
    strapiContactUsPage {
      locationsSection {
        contactButtonText
        contactFormModalTitle
      }
    }
    Trainers: allStrapiPersonalTrainers(
      filter: { locations: { elemMatch: { title: { eq: $title } } } }
    ) {
      edges {
        node {
          name
          slug
          id
          qualification
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 520, height: 520)
              }
            }
          }
        }
      }
    }
    TrainerContent: strapiPersonalTrainers(
      locations: { elemMatch: { title: { eq: $title } } }
    ) {
      locations {
        title
        trainers {
          title
          tagline
          content
          seeAllText
        }
      }
    }
    strapiGlobal {
      banner: defaultBanner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
